const FastForwardIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_346_5092)">
      <path
        d="M8.91634 13.1667L14.9163 8.50004L8.91634 3.83337V13.1667Z"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.58301 13.1667L7.58301 8.50004L1.58301 3.83337V13.1667Z"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_346_5092">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.25 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FastForwardIcon;
