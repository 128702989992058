import React from "react";
import { cn } from "@/app/helpers/utils";
import styles from "./styles.module.scss";
import AlertIcon from "../icons/AlertIcon";

type Props = {
  title: string;
  icon: React.ReactElement | null;
  description: string;
  content: React.ReactNode;
  className?: string;
  error?: boolean;
};

const Alert: React.FC<Props> = ({
  className,
  title,
  description,
  content,
  icon,
  error,
}) => {
  return (
    <div className={cn(styles.Alert, className, error && styles.hasError)}>
      <div className={styles.iconWrapper}>
        {error ? <AlertIcon /> : icon}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.textWrapper}>
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
        </div>

        <div>{content}</div>
      </div>
    </div>
  );
};

export default Alert;
