"use client";

import BackgroundHero from "@/components/BackgroundHero";
import styles from "./styles.module.scss";
import Button from "@/components/Button";
import { useEffect, useState } from "react";
import { useIsMobile } from "@/hooks/use-mobile";
import OTPInput from "@/components/OTPInput";
import PhoneInput from "@/components/PhoneInput";
import axios from "axios";
import { API_URL } from "../../../constants";
import { useSearchParams } from "next/navigation";
import apiClient from "@/apiClient";
import userStore from "@/store";
import { formatError } from "@/app/helpers/utils";

const Start = () => {
  const [isContinueButtomDisabled, setIsContinueButtomDisabled] =
    useState(false);

  const [isVerifyStep, setIsVerifyStep] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpValue, setOtpValue] = useState("");

  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);
  const [isResendButtonSent, setIsResendButtonSent] = useState(false);
  const [timer, setTimer] = useState(0);

  const [errors, setErrors] = useState({
    phone: "",
    otp: "",
  });

  const { setUserStatus, setUser, setCustomFields, setPlans, setNoNotesData } =
    userStore();

  const searchParams = useSearchParams();

  const referralCode = searchParams.get("r");

  const isMobile = useIsMobile();

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (isResendButtonSent) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer - 1 < 0) {
            setIsResendButtonSent(false);
            setIsResendButtonDisabled(false);

            return prevTimer;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isResendButtonSent]);

  const getCustomFields = async () => {
    try {
      const response = await apiClient.get(`${API_URL}/customer/me/fields`);

      setCustomFields(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getMe = async () => {
    try {
      const response = await apiClient.get(`${API_URL}/customer/me`);

      setUser(response.data);
      setUserStatus(response.data.status);
    } catch (error) {
      console.error(error);
    }
  };

  const checkIfNotesDataExists = async () => {
    try {
      const response = await apiClient.get(`${API_URL}/customer/note`, {
        params: { limit: 1, offset: 0 },
      });
      setNoNotesData(response.data.data.length === 0);
    } catch (error) {
      console.error(error);
      setNoNotesData(true);
    }
  };

  const getPlans = async () => {
    try {
      const response = await apiClient.get(
        `${API_URL}/customer/billings/plans`
      );
      setPlans(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleContinueFirstStep = async () => {
    if (!phoneNumber) {
      setErrors((prev) => ({
        ...prev,
        phone:
          "Enter a phone number. Your account will be linked to this number.",
      }));
      return;
    }
    setIsContinueButtomDisabled(true);
    setErrors({ phone: "", otp: "" });

    try {
      await axios.post(`${API_URL}/customer/auth/log-in`, {
        phone: phoneNumber,
      });
      setIsVerifyStep(true);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      const textError = Array.isArray(errorMessage)
        ? errorMessage[0]
        : errorMessage;

      setErrors((prev) => ({
        ...prev,
        phone: textError,
      }));
    } finally {
      setIsContinueButtomDisabled(false);
    }
  };

  const handleResend = async () => {
    setIsResendButtonDisabled(true);
    setErrors({ phone: "", otp: "" });

    try {
      await axios.post(`${API_URL}/customer/auth/log-in`, {
        phone: phoneNumber,
      });
      setTimer(59);
      setIsResendButtonSent(true);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      const textError = Array.isArray(errorMessage)
        ? errorMessage[0]
        : errorMessage;

      setErrors((prev) => ({
        ...prev,
        otp: textError,
      }));
      setIsResendButtonDisabled(false);
    }
  };

  const handleVerify = async () => {
    if (!otpValue) {
      setErrors((prev) => ({
        ...prev,
        otp: "Enter the code sent to you by SMS.",
      }));
      return;
    }

    setIsContinueButtomDisabled(true);
    setErrors({ phone: "", otp: "" });

    try {
      const response = await axios.post(`${API_URL}/customer/auth/verify`, {
        phone: phoneNumber,
        otp: otpValue,
        referralCode: referralCode || null,
      });

      localStorage.setItem("token", response.data);
      await Promise.all([
        getMe(),
        getCustomFields(),
        getPlans(),
        checkIfNotesDataExists(),
      ]);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      const textError = Array.isArray(errorMessage)
        ? errorMessage[0]
        : errorMessage;

      setErrors((prev) => ({
        ...prev,
        otp: textError,
      }));
    } finally {
      setIsContinueButtomDisabled(false);
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.heroContainer}>
          <BackgroundHero
            showIcon
            text={
              isMobile
                ? `${
                    !isVerifyStep
                      ? "Experience the future of personal assistants. Let’s first set you up. To start, enter your phone number."
                      : `Verify your phone number by entering the 6-digit code sent to ${phoneNumber}`
                  }`
                : "Experience the future of personal assistants."
            }
            height="100%"
            width="100%"
          />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <h2>
              {!isVerifyStep ? (
                <span>
                  Let’s first set you up.
                  <br />
                  To start, enter your phone number.
                </span>
              ) : (
                `Verify your phone number by entering the 6-digit code sent to ${phoneNumber}`
              )}
            </h2>
            <div className={styles.otpContainer}>
              {!isVerifyStep ? (
                <PhoneInput
                  value={phoneNumber}
                  setValue={setPhoneNumber}
                  description="Your account will be linked to this number."
                  label="Phone"
                  error={!!errors.phone}
                  errorText={formatError(errors.phone)}
                  key="phone"
                />
              ) : (
                <>
                  <OTPInput
                    length={6}
                    onChange={(values) => setOtpValue(values)}
                    error={!!errors.otp}
                    errorText={formatError(errors.otp)}
                    key="otp"
                  />
                  {isResendButtonSent ? (
                    <span className={styles.info}>
                      New code has been sent. Request another in {timer}s.
                    </span>
                  ) : (
                    <Button
                      label="Resend code"
                      typeVariant="link"
                      onClick={handleResend}
                      loading={isResendButtonDisabled}
                    />
                  )}
                </>
              )}
            </div>
            <div className={styles.desktopButton}>
              <Button
                label="Continue"
                type="button"
                onClick={isVerifyStep ? handleVerify : handleContinueFirstStep}
                loading={isContinueButtomDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          label="Continue"
          type="button"
          onClick={isVerifyStep ? handleVerify : handleContinueFirstStep}
          loading={isContinueButtomDisabled}
        />
      </div>
    </section>
  );
};

export default Start;
