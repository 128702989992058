// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
"use client";
import { useForm } from "react-hook-form";
import BackgroundHero from "@/components/BackgroundHero";
import styles from "./styles.module.scss";
import Button from "@/components/Button";
import Input from "@/components/Input";
import Textarea from "@/components/Textarea";
import Checkbox from "@/components/Checkbox";
import { useState } from "react";
import userStore from "@/store";
import apiClient from "@/apiClient";
import { API_URL } from "../../../constants";
import { useIsMobile } from "@/hooks/use-mobile";
import { formatError, getFieldErrorByFieldName } from "@/app/helpers/utils";
import DatePicker from "@/components/DatePicker";

const Onboarding = () => {
  const { user, setUserStatus, setUser, customFields, isFromMagicLink } =
    userStore();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...(user?.customFields || {}),
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const isMobile = useIsMobile();

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      const response = await apiClient.patch(`${API_URL}/customer/me`, {
        customFields: Object.entries(data).reduce(
          (acc, [key, value]) => ({ ...acc, [key]: value }),
          {}
        ),
      });

      setUser(response.data);
      setUserStatus(response.data.status);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const terms = (
    <p className={styles.terms}>
      I agree to{" "}
      <span onClick={() => window.open("/terms-of-use", "_blank")}>
        the terms of use
      </span>{" "}
      and{" "}
      <span onClick={() => window.open("/privacy-policy", "_blank")}>
        privacy policy
      </span>
      .
    </p>
  );

  let backgroundHeroText =
    "Thank you for validating your phone number. Now, complete your setup.";

  if (isFromMagicLink) {
    backgroundHeroText = "Welcome! Complete your setup.";
  }

  if (!isMobile) {
    backgroundHeroText = "Experience the future of personal assistants.";
  }

  let headerText =
    "Thank you for validating your phone number. Now, complete your setup.";

  if (isFromMagicLink) {
    headerText = "Welcome! Complete your setup.";
  }

  return (
    <section className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.heroContainer}>
          <BackgroundHero
            showIcon
            text={backgroundHeroText}
            height="100%"
            width="100%"
          />
        </div>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <h1 className={styles.title}>{headerText}</h1>
            {customFields.map((field) => {
              switch (field.type) {
                case "checkbox":
                  return (
                    <div key={field.name}>
                      <Checkbox
                        {...register(
                          field.name,
                          field.required && {
                            required: `${field.label} is required`,
                          }
                        )}
                        label={`${field.label}${
                          !field.required ? " (optional)" : ""
                        }`}
                        id={field.name}
                        error={!!errors?.[field.name]}
                        errorText={formatError(
                          String(errors?.[field.name]?.message)
                        )}
                      />
                    </div>
                  );
                case "textarea":
                  return (
                    <div key={field.name}>
                      <Textarea
                        placeholder={field.placeholder}
                        label={`${field.label}${
                          !field.required ? " (optional)" : ""
                        }`}
                        description={field.description}
                        {...register(
                          field.name,
                          field.required && {
                            required:
                              getFieldErrorByFieldName(field.name) ||
                              `${field.label} is required.`,
                          }
                        )}
                        error={!!errors?.[field.name]}
                        errorText={formatError(
                          String(errors?.[field.name]?.message)
                        )}
                        id={field.name}
                      />
                    </div>
                  );
                case "date":
                  return (
                    <div key={field.name}>
                      <DatePicker
                        dateString={getValues(field.name)}
                        setFormValue={(value) => setValue(field.name, value)}
                        placeholder={field.placeholder}
                        label={`${field.label}${
                          !field.required ? " (optional)" : ""
                        }`}
                        description={field.description}
                        error={!!errors?.[field.name]}
                        errorText={formatError(
                          String(errors?.[field.name]?.message)
                        )}
                        id={field.name}
                      ></DatePicker>
                    </div>
                  );
                case "text":
                default:
                  return (
                    <div key={field.name}>
                      <Input
                        placeholder={field.placeholder}
                        label={`${field.label}${
                          !field.required ? " (optional)" : ""
                        }`}
                        type={field.type}
                        description={field.description}
                        {...register(
                          field.name,
                          field.required && {
                            required:
                              getFieldErrorByFieldName(field.name) ||
                              `${field.label} is required.`,
                          }
                        )}
                        error={!!errors?.[field.name]}
                        errorText={formatError(
                          String(errors?.[field.name]?.message)
                        )}
                        id={field.name}
                      />
                    </div>
                  );
              }
            })}

            <Checkbox
              checked={isAgreed}
              setChecked={setIsAgreed}
              label={terms}
              id="agreed"
            />

            <div className={styles.desktopButton}>
              <Button
                label="Continue"
                type="button"
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
                disabled={!isAgreed}
              />
            </div>
          </form>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          label="Continue"
          type="button"
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
          disabled={!isAgreed}
        />
      </div>
    </section>
  );
};

export default Onboarding;
