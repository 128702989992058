"use client";

import { UserStatuses } from "@/lib/types";
import Onboarding from "@/page-components/Onboarding";
import Start from "@/page-components/Start";
import Waitlist from "@/page-components/Waitlist";
import userStore from "@/store";
import { useRouter } from "next/navigation";

export default function Home() {
  const { userStatus } = userStore();
  const router = useRouter();

  return (
    <>
      {userStatus === UserStatuses.NEW ? (
        <Onboarding />
      ) : userStatus === UserStatuses.ACTIVE ? (
        router.push("/notes")
      ) : userStatus === UserStatuses.ONBOARDED ? (
        <Waitlist />
      ) : userStatus === UserStatuses.START ? (
        <Start />
      ) : userStatus === UserStatuses.EXPIRED ? (
        <Start />
      ) : (
        <Start />
      )}
    </>
  );
}
