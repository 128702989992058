import React from "react";
import styles from "./styles.module.scss";
import Animation from "../Animation";
import BackgroundLogo from "../icons/BackgroundLogo";

type Props = {
  text?: string;
  className?: string;
  showIcon?: boolean;
  width?: number | string;
  height?: number | string;
};

const BackgroundHero: React.FC<Props> = ({
  text,
  showIcon,
  width = 500,
  height = 500,
}) => {
  return (
    <Animation width={width} height={height}>
      <div className={styles.textWrapper}>
        {showIcon && (
          <div>
            <BackgroundLogo />
          </div>
        )}
        <p className={styles.text}>{text}</p>
      </div>
    </Animation>
  );
};

export default BackgroundHero;
