const CopyIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_448_3746)">
      <path
        d="M2.91634 11.1667C2.18301 11.1667 1.58301 10.5667 1.58301 9.83337V3.16671C1.58301 2.43337 2.18301 1.83337 2.91634 1.83337H9.58301C10.3163 1.83337 10.9163 2.43337 10.9163 3.16671M6.91634 5.83337H13.583C14.3194 5.83337 14.9163 6.43033 14.9163 7.16671V13.8334C14.9163 14.5698 14.3194 15.1667 13.583 15.1667H6.91634C6.17996 15.1667 5.58301 14.5698 5.58301 13.8334V7.16671C5.58301 6.43033 6.17996 5.83337 6.91634 5.83337Z"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_448_3746">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.25 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CopyIcon;
