import React, { useState } from "react";
import { Label } from "../ui/label";
import IntlTelInput from "intl-tel-input/react";
import "intl-tel-input/build/css/intlTelInput.css";
import styles from "./styles.module.scss";
import { cn } from "@/app/helpers/utils";

type Props = {
  disabled?: boolean;
  value?: string;
  setValue?: (value: string) => void;
  label?: string;
  description?: string | React.ReactNode;
  className?: string;
  type?: string;
  error?: boolean;
  errorText?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const errorMap = [
  "Invalid number",
  "Invalid country code",
  "Too short",
  "Too long",
  "Invalid number",
];

const PhoneInput: React.FC<Props> = ({
  label,
  description,
  value,
  setValue,
  error,
  errorText,
  disabled,
  ...props
}) => {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [noticeError, setNoticeError] = useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = (): void => {
    if (!isValid) {
      const errorMessage = errorMap[errorCode || 0] || "Invalid number";
      setNoticeError(`${errorMessage}`);
    }
  };

  return (
    <div className={styles.PhoneInput}>
      {label && (
        <Label
          htmlFor={props?.id}
          className={cn("field-label", error && styles.labelError)}
        >
          {label}
        </Label>
      )}
      <div className={cn(styles.phoneInput, error && "int-error")}>
        <IntlTelInput
          initialValue={value || undefined}
          onChangeNumber={setValue}
          onChangeValidity={setIsValid}
          onChangeErrorCode={setErrorCode}
          inputProps={{ id: props.id }}
          disabled={disabled}
          initOptions={{
            initialCountry: "us",
            utilsScript:
              "https://cdn.jsdelivr.net/npm/intl-tel-input@24.7.0/build/js/utils.js",
          }}
        />
      </div>
      {description && !error && (
        <p className="field-description">{description}</p>
      )}
      {(noticeError || (error && errorText)) && (
        <p className="field-error">{noticeError || errorText}</p>
      )}
    </div>
  );
};

PhoneInput.displayName = "PhoneInput";

export default PhoneInput;
