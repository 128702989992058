const BackgroundLogo = () => (
  <svg
    width="123"
    height="20"
    viewBox="0 0 123 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_99_4925)">
      <path
        d="M40.0458 2.9718V17.0435H37.7377V7.40603L33.4455 17.0435H31.8462L27.5335 7.40603V17.0435H25.2255V2.9718H27.7153L32.6561 14.0063L37.5764 2.9718H40.0468H40.0458Z"
        fill="white"
      />
      <path
        d="M42.5059 8.43851C42.9716 7.57454 43.6058 6.90359 44.4095 6.42361C45.2122 5.94464 46.1007 5.70465 47.0719 5.70465C47.9491 5.70465 48.7151 5.87724 49.3697 6.2214C50.0243 6.56556 50.5472 6.99448 50.9393 7.50714V5.88745H53.2678V17.0435H50.9393V15.383C50.5472 15.9099 50.0141 16.348 49.3401 16.6993C48.665 17.0496 47.895 17.2263 47.0321 17.2263C46.0731 17.2263 45.1959 16.9802 44.4003 16.4869C43.6038 15.9947 42.9726 15.3064 42.5069 14.422C42.0413 13.5386 41.8084 12.5357 41.8084 11.4154C41.8084 10.2951 42.0413 9.30351 42.5069 8.43954L42.5059 8.43851ZM50.4634 9.45159C50.1458 8.8848 49.7312 8.45281 49.2185 8.15563C48.7059 7.85947 48.1524 7.71037 47.558 7.71037C46.9636 7.71037 46.4101 7.85539 45.8975 8.14542C45.3848 8.43545 44.9692 8.86131 44.6526 9.42095C44.335 9.98161 44.1767 10.6454 44.1767 11.4154C44.1767 12.1855 44.335 12.8605 44.6526 13.4406C44.9692 14.0206 45.3879 14.4628 45.9077 14.7672C46.4275 15.0705 46.9769 15.2226 47.558 15.2226C48.1391 15.2226 48.7049 15.0745 49.2185 14.7774C49.7312 14.4802 50.1469 14.0451 50.4634 13.4712C50.78 12.8973 50.9393 12.2263 50.9393 11.4563C50.9393 10.6863 50.78 10.0194 50.4634 9.45261V9.45159Z"
        fill="white"
      />
      <path
        d="M63.7856 6.25204C64.481 6.61662 65.0243 7.15686 65.4154 7.87173C65.8066 8.58762 66.0027 9.45159 66.0027 10.4636V17.0445H63.7151V10.8078C63.7151 9.80902 63.4649 9.04309 62.9655 8.51C62.4661 7.97691 61.7839 7.71037 60.921 7.71037C60.058 7.71037 59.3717 7.97691 58.8662 8.51C58.3597 9.04309 58.1074 9.81004 58.1074 10.8078V17.0445H55.7994V5.88745H58.1074V7.16298C58.4853 6.70445 58.9683 6.34599 59.5556 6.08966C60.1428 5.83333 60.7668 5.70465 61.4285 5.70465C62.3058 5.70465 63.0921 5.88745 63.7876 6.25204H63.7856Z"
        fill="white"
      />
      <path
        d="M68.3617 8.43851C68.8274 7.57454 69.4616 6.90358 70.2653 6.4236C71.068 5.94463 71.9626 5.70464 72.9481 5.70464C73.6773 5.70464 74.3962 5.86396 75.104 6.18054C75.8127 6.49815 76.3764 6.91992 76.7951 7.44586V2.06085H79.1236V17.0435H76.7951V15.3625C76.4173 15.9028 75.8944 16.348 75.2265 16.6993C74.5576 17.0496 73.7917 17.2263 72.9287 17.2263C71.9565 17.2263 71.069 16.9802 70.2663 16.4869C69.4636 15.9947 68.8295 15.3064 68.3627 14.422C67.8971 13.5386 67.6642 12.5357 67.6642 11.4154C67.6642 10.2951 67.8971 9.3035 68.3627 8.43953L68.3617 8.43851ZM76.3192 9.45158C76.0016 8.88479 75.587 8.4528 75.0743 8.15562C74.5617 7.85946 74.0082 7.71036 73.4138 7.71036C72.8194 7.71036 72.267 7.85538 71.7543 8.14541C71.2416 8.43544 70.826 8.8613 70.5094 9.42094C70.1918 9.9816 70.0335 10.6454 70.0335 11.4154C70.0335 12.1854 70.1918 12.8605 70.5094 13.4405C70.826 14.0206 71.2447 14.4628 71.7645 14.7671C72.2843 15.0705 72.8337 15.2226 73.4148 15.2226C73.9959 15.2226 74.5617 15.0745 75.0754 14.7774C75.588 14.4802 76.0027 14.0451 76.3203 13.4712C76.6368 12.8972 76.7962 12.2263 76.7962 11.4563C76.7962 10.6863 76.6379 10.0194 76.3203 9.4526L76.3192 9.45158Z"
        fill="white"
      />
      <path
        d="M85.3082 6.18159C85.8679 5.865 86.5327 5.70569 87.3027 5.70569V8.09539H86.7155C85.8107 8.09539 85.1254 8.32517 84.6607 8.78371C84.1951 9.24327 83.9622 10.0388 83.9622 11.1734V17.0456H81.6542V5.88747H83.9622V7.50716C84.2992 6.94037 84.7486 6.49817 85.3082 6.18057V6.18159Z"
        fill="white"
      />
      <path
        d="M88.8519 8.43851C89.3176 7.57454 89.9518 6.90359 90.7555 6.42361C91.5582 5.94464 92.4457 5.70465 93.4179 5.70465C94.2951 5.70465 95.0611 5.87724 95.7157 6.2214C96.3703 6.56556 96.8932 6.99448 97.2843 7.50714V5.88745H99.6128V17.0435H97.2843V15.383C96.8922 15.9099 96.3601 16.348 95.6851 16.6993C95.01 17.0496 94.24 17.2263 93.3771 17.2263C92.4181 17.2263 91.5409 16.9802 90.7443 16.4869C89.9477 15.9947 89.3166 15.3064 88.8509 14.422C88.3852 13.5386 88.1524 12.5357 88.1524 11.4154C88.1524 10.2951 88.3852 9.30351 88.8509 8.43954L88.8519 8.43851ZM96.8094 9.45159C96.4918 8.8848 96.0772 8.45281 95.5645 8.15563C95.0519 7.85947 94.4984 7.71037 93.904 7.71037C93.3096 7.71037 92.7572 7.85539 92.2435 8.14542C91.7308 8.43545 91.3152 8.86131 90.9986 9.42095C90.682 9.98161 90.5227 10.6454 90.5227 11.4154C90.5227 12.1855 90.681 12.8605 90.9986 13.4406C91.3152 14.0206 91.7349 14.4628 92.2537 14.7672C92.7735 15.0705 93.3229 15.2226 93.904 15.2226C94.4851 15.2226 95.0509 15.0745 95.5645 14.7774C96.0772 14.4802 96.4918 14.0451 96.8094 13.4712C97.126 12.8973 97.2853 12.2263 97.2853 11.4563C97.2853 10.6863 97.127 10.0194 96.8094 9.45261V9.45159Z"
        fill="white"
      />
      <path
        d="M106.558 11.4757L111.701 17.0435H108.583L104.452 12.2447V17.0435H102.144V2.06085H104.452V10.7669L108.502 5.88745H111.701L106.558 11.4757Z"
        fill="white"
      />
      <path
        d="M122.999 12.3264H114.475C114.542 13.2169 114.873 13.9328 115.468 14.4731C116.061 15.0133 116.79 15.2829 117.654 15.2829C118.896 15.2829 119.773 14.7641 120.287 13.7245H122.777C122.44 14.7508 121.829 15.5903 120.944 16.2449C120.06 16.8995 118.963 17.2273 117.654 17.2273C116.588 17.2273 115.633 16.9884 114.789 16.5084C113.946 16.0294 113.284 15.3544 112.805 14.4833C112.326 13.6132 112.086 12.6042 112.086 11.4563C112.086 10.3084 112.319 9.30046 112.785 8.42934C113.25 7.55822 113.905 6.88727 114.749 6.41443C115.592 5.9416 116.56 5.70569 117.654 5.70569C118.748 5.70569 119.644 5.93547 120.469 6.39401C121.292 6.85356 121.933 7.49797 122.392 8.32824C122.85 9.15851 123.08 10.1134 123.08 11.1928C123.08 11.6115 123.053 11.9894 122.999 12.3264ZM120.671 10.4637C120.657 9.61296 120.353 8.93179 119.76 8.41913C119.165 7.90647 118.43 7.65013 117.553 7.65013C116.756 7.65013 116.075 7.9034 115.508 8.40892C114.942 8.91545 114.604 9.60071 114.496 10.4637H120.672H120.671Z"
        fill="white"
      />
      <path
        d="M16.549 0.169415C14.7455 -0.394311 13.3842 0.56872 12.2904 1.34282C11.6072 1.82587 10.9618 2.28236 10.3562 2.28236C9.75062 2.28236 9.10519 1.82587 8.42198 1.34282C7.32823 0.56872 5.96692 -0.394311 4.1634 0.169415C1.04862 1.14266 0.225494 6.94025 0.225494 9.86917C0.225494 15.4554 4.77002 19.9999 10.3562 19.9999C15.9424 19.9999 20.4869 15.4554 20.4869 9.86917C20.4869 6.94025 19.6638 1.14266 16.549 0.169415ZM10.3562 17.6337C6.07517 17.6337 2.59273 14.1502 2.59273 9.87019C2.59273 6.34895 3.67831 2.80115 4.8701 2.42942C5.49102 2.23539 6.01287 2.5387 7.05556 3.27603C7.96651 3.92044 8.99898 4.65062 10.3572 4.65062C11.7155 4.65062 12.7469 3.92044 13.6589 3.27603C14.7006 2.5387 15.2214 2.23437 15.8444 2.42942C17.0362 2.80218 18.1217 6.34895 18.1217 9.87019C18.1217 14.1512 14.6383 17.6337 10.3583 17.6337H10.3562Z"
        fill="white"
      />
      <path
        d="M7.25981 10.8824C8.08214 10.8824 8.74878 10.2157 8.74878 9.39339C8.74878 8.57105 8.08214 7.90442 7.25981 7.90442C6.43747 7.90442 5.77084 8.57105 5.77084 9.39339C5.77084 10.2157 6.43747 10.8824 7.25981 10.8824Z"
        fill="white"
      />
      <path
        d="M13.4526 10.8824C14.275 10.8824 14.9416 10.2157 14.9416 9.39339C14.9416 8.57105 14.275 7.90442 13.4526 7.90442C12.6303 7.90442 11.9636 8.57105 11.9636 9.39339C11.9636 10.2157 12.6303 10.8824 13.4526 10.8824Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_99_4925">
        <rect
          width="122.549"
          height="20"
          fill="white"
          transform="translate(0.225494)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default BackgroundLogo;
