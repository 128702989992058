import React from "react";
import { cn } from "@/app/helpers/utils";
import styles from "./styles.module.scss";

type AnimationVariant = "primary" | "secondary";

type Props = {
  className?: string;
  width?: number | string;
  height?: number | string;
  variant?: AnimationVariant;
  children?: React.ReactNode;
};

const Animation: React.FC<Props> = ({
  className,
  width,
  height,
  variant = "primary",
  children,
}) => {
  return (
    <div
      className={cn(styles.Animation, className, styles[variant])}
      style={{ width, height }}
    >
      <div className={cn(styles.blobs)}>
        <div className={cn(styles.blob, styles.blobA)}></div>
        <div className={cn(styles.blob, styles.blobB)}></div>
        <div className={cn(styles.blob, styles.blobC)}></div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Animation;
