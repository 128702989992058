"use client";

import BackgroundHero from "@/components/BackgroundHero";
import styles from "./styles.module.scss";
import Alert from "@/components/Alert";
import Input from "@/components/Input";
import Button from "@/components/Button";
import ProgressBar from "@/components/ProgressBar";
import { useToast } from "@/hooks/use-toast";
import CopyIcon from "@/components/icons/CopyIcon";
import userStore from "@/store";
import FastForwardIcon from "@/components/icons/FastForwardIcon";
import { formatNumberWithCommas } from "@/app/helpers/utils";
import { useIsMobile } from "@/hooks/use-mobile";
import { useEffect } from "react";

const Waitlist = () => {
  const { user } = userStore();
  const { toast } = useToast();

  const isMobile = useIsMobile();
  const queue = formatNumberWithCommas((user?.queue || 1) + 1000);

  const referralCode = `${user?.referralCode || ""}`;

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(`dashboard.callmandrake.com/?r=${referralCode}`)
      .then(() => {
        toast({
          title: "Copied to clipboard!",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    localStorage.removeItem("referralCredits");
  }, []);

  const alertContent = (
    <div className={styles.alertContent}>
      <div className={styles.form}>
        <Input placeholder={`mandrake.com?r=${referralCode}`} tabIndex={-1}/>
        <Button
          label="Copy"
          leftIcon={<CopyIcon />}
          onClick={handleCopyToClipboard}
        />
      </div>
      <ProgressBar
        label={`${user?.referrersCount} / 3 invited`}
        used={3 - (user?.referrersCount || 0)}
        total={3}
        size="big"
      />
    </div>
  );

  return (
    <section className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.heroContainer}>
          <BackgroundHero
            showIcon
            text={
              isMobile
                ? `Congrats! You are ${queue} in queue on the waitlist.`
                : "Experience the future of personal assistants."
            }
            height="100%"
            width="100%"
          />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <h1 className={styles.title}>
              Congrats! You are number {queue} on the waitlist.
            </h1>
            <Alert
              title="Jump the queue by referring."
              description="Use the following referral code to get instant access. You only need 3 people to sign up with it – and you’ll be given access immediately."
              icon={<FastForwardIcon />}
              content={alertContent}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Waitlist;
