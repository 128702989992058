import React from "react";
import { cn } from "@/app/helpers/utils";
import styles from "./styles.module.scss";

type ProgressBarVariant = "small" | "big" | "long";

type Props = {
  label?: string;
  total: number;
  used: number;
  className?: string;
  size?: ProgressBarVariant;
};

const ProgressBar: React.FC<Props> = ({
  className,
  label,
  total,
  used,
  size = "small",
}) => {
  let percentage = Math.min((used / total) * 100, 100);

  if (total === 0) {
    percentage = 100;
  }

  return (
    <div className={cn(styles.progressBarContainer, className, styles[size])}>
      <div className={styles.barWrapper}>
        <div className={styles.bar} style={{ width: `${100 - percentage}%` }} />
      </div>
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default ProgressBar;
