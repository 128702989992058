// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import { cn } from "@/app/helpers/utils";

const OTPInput = ({ length, onChange, error, errorText }) => {
  const [values, setValues] = useState(Array(length).fill(""));
  const inputs = useRef([]);

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (value === " " || !/^\d*$/.test(value)) {
      return;
    }

    if (value.length <= 1) {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
      if (index < length - 1 && value !== "") {
        inputs.current[index + 1].setSelectionRange(0, 1);
        inputs.current[index + 1].focus();
      }
      onChange(newValues.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (
      e.target.value.length &&
      /^\d*$/.test(e.key) &&
      index !== length - 1 &&
      !window.getSelection()?.toString()
    ) {
      handleChange(e, index);
    }
    if (e.key === "Backspace" && !values[index] && index > 0) {
      inputs.current[index - 1].setSelectionRange(0, 1);

      setTimeout(() => {
        inputs.current[index - 1].focus();
      }, 0);
    } else if (e.key === "Backspace" && index === 0) {
      const newValues = [...values];
      newValues[index] = "";
      setValues(newValues);
      onChange(newValues.join(""));
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputs.current[index - 1].setSelectionRange(0, 1);
      setTimeout(() => {
        inputs.current[index - 1].focus();
      }, 0);
    } else if (e.key === "ArrowLeft" && index === 0) {
      setTimeout(() => {
        inputs.current[index].setSelectionRange(0, 1);
        inputs.current[index].focus();
      }, 0);
    } else if (e.key === "ArrowRight" && index < length - 1) {
      inputs.current[index + 1].setSelectionRange(0, 1);
      setTimeout(() => {
        inputs.current[index + 1].focus();
      }, 0);
    } else if (e.key === "ArrowRight" && index === length - 1) {
      setTimeout(() => {
        inputs.current[index].setSelectionRange(0, 1);
        inputs.current[index].focus();
      }, 0);
    } else if (e.keyCode === 32 && index < length - 1) {
      inputs.current[index + 1].setSelectionRange(0, 1);
      setTimeout(() => {
        inputs.current[index + 1].focus();
      }, 0);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, length);
    const newValues = [...values];
    for (let i = 0; i < pasteData.length; i++) {
      newValues[i] =
        pasteData[i] === " " || !/^\d*$/.test(pasteData[i]) ? "" : pasteData[i];
    }
    setValues(newValues);
    onChange(newValues.join(""));
    inputs.current[Math.min(pasteData.length, length) - 1].focus();
  };

  return (
    <div className={cn(styles.otp, error && styles.hasError)}>
      <div className={styles.list}>
        {Array.from({ length }).map((_, i) => (
          <input
            key={i}
            type="text"
            name="OTPInput"
            maxLength={1}
            autoComplete="off"
            value={values[i]}
            tabIndex={0}
            onChange={(e) => {
              handleChange(e, i);
            }}
            onKeyDown={(e) => handleKeyDown(e, i)}
            onMouseDown={() => {
              if (values[i]) {
                setTimeout(() => {
                  inputs.current[i]?.setSelectionRange(0, 1);
                }, 0);
              }
            }}
            className={styles.slot}
            onPaste={handlePaste}
            inputMode="numeric"
            ref={(el) => (inputs.current[i] = el)}
          />
        ))}
      </div>
      {error && errorText && <p className="field-error">{errorText}</p>}
    </div>
  );
};

export default OTPInput;
